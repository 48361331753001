import {
    SET_ACTIVE_PAGE,
    SET_ACTIVE_PARTITION_IDS,
    STORE_PARTITIONSETS,
    TOGGLE_NOTIFICATION_PANE,
    SET_ORG_USER_ACCESS,
    SET_ORG_ISSUERS,
    SET_DISPLAYVIEW,
    SET_CHART_DATE_RANGE,
    STORE_CUSTOMFIELDS,
    SET_FILE_TO_IMPORT,
    SET_ORGANIZATION_TOKENS,
    SET_USER_PROFILE,
    SET_USER_ORGANIZATION,
} from './mutation-types';
import { IAppState } from './types';
import { IUserOrganizationAccess, IUserProfile, IUserOrganization, IUserOrganizationIssuer } from '../../types/IUser';
import { List as ImmList } from 'immutable';
import { ICustomField } from '../../types/ICustomField';
import { IPartitionSet } from '../../types/IPartition';

export default {
    [SET_ACTIVE_PAGE]: (state: IAppState, { value }: { value: string }) => {
        Object.assign(state, {
            activePage: value,
        });
    },

    [SET_ACTIVE_PARTITION_IDS]: (state: IAppState, { value }: { value: number[] }) => {
        Object.assign(state, {
            activePartitionIds: value,
        });
    },

    [TOGGLE_NOTIFICATION_PANE]: (state: IAppState, { value }: { value: string }) => {
        Object.assign(state, {
            notificationPaneIsOpen: value,
        });
    },

    [SET_ORG_USER_ACCESS]: (state: IAppState, { users }: { users: IUserOrganizationAccess[] }) => {
        Object.assign(state, {
            organizationUsers: users,
        });
    },

    [SET_ORG_ISSUERS]: (state: IAppState, { issuers }: { issuers: IUserOrganizationIssuer[] }) => {
        Object.assign(state, {
            organizationIssuers: issuers,
        });
    },

    [SET_ORGANIZATION_TOKENS]: (state: IAppState, { tokens }: { tokens: ImmList<IUserOrganization> }) => {
        Object.assign(state, {
            organizationTokens: tokens,
        });
    },

    [SET_DISPLAYVIEW]: (state: IAppState, { displayView }: { displayView: string }) => {
        Object.assign(state, {
            displayView,
        });
    },

    [SET_CHART_DATE_RANGE]: (state: IAppState, { start, end }: { start: number; end: number }) => {
        Object.assign(state, {
            chartDateRange: [start, end],
        });
    },
    [STORE_CUSTOMFIELDS]: (state: IAppState, { customFields }: { customFields: ImmList<ICustomField> }) => {
        Object.assign(state, {
            customFields,
        });
    },
    [STORE_PARTITIONSETS]: (state: IAppState, { partitionSets }: { partitionSets: ImmList<IPartitionSet> }) => {
        Object.assign(state, {
            partitionSets,
        });
    },
    [SET_USER_PROFILE]: (state: IAppState, { user }: { user: IUserProfile }) => {
        Object.assign(state, { user });
    },
    [SET_USER_ORGANIZATION]: (state: IAppState, { organization }: { organization: IUserOrganization }) => {
        Object.assign(state, { organization });
    },
    [SET_FILE_TO_IMPORT]: (state: IAppState, { fileToImport }: { fileToImport: File | null }) => {
        Object.assign(state, { fileToImport });
    },
};
