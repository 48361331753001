import Rx from 'rx';
import { Map as ImmMap } from 'immutable';

import { defineStore } from 'pinia';
import { IActivityRevision } from '../../types/IActivityRevision';
import AppServer from '../../api/AppServer';
import Query from '../../api/Query';
import QueryCommand from '../../api/QueryCommand';
import { IContentBlock } from '../../types/IContentBlock';
import WriteCommand from '../../api/WriteCommand';

interface ISelectedBlock {
    id: string | number;
    template: any;
    block: any;
    gridId: any;
}

interface ISelectedGrid {
    id: string | number;
    grid: any;
}

interface IUIStateLam {
    _revision: ImmMap<keyof IActivityRevision, any>;
    _assets: IContentBlock[];
    _selectedBlock: ISelectedBlock | null;
    _selectedGrid: ISelectedGrid | null;
    _defaultCommPreference: COMM_PREFERENCE;
    _selectedPartitions: number[];
    _selectedAssetId: string;
    _hoveredItem: any;
    _selectedNav: any;
}

const FETCH_ASSET_LIMIT = 100;

let fetchActivityRevisionDisposable!: Rx.Disposable;
let fetchAssetsDisposable!: Rx.Disposable;
let updatePartitionOptinDisposable!: Rx.Disposable;
let updatePartitionContentDisposable!: Rx.Disposable;
let updatePartitionLamGroupsDisposable!: Rx.Disposable;

export type COMM_PREFERENCE = 'optin' | 'optout';

// FIXME Add LSM ACL restrictions for fetches and updates
export const useStoreLAM = defineStore('lsm', {
    state: () => {
        return {
            _revision: ImmMap<keyof IActivityRevision, any>(),
            _assets: [] as IContentBlock[],
            _selectedBlock: null as ISelectedBlock | null,
            _selectedGrid: null as ISelectedGrid | null,
            _defaultCommPreference: 'optin' as COMM_PREFERENCE,
            _selectedPartitions: [] as number[],
            _selectedAssetId: '',
            _hoveredItem: null as ISelectedBlock | ISelectedGrid | null,
            _selectedNav: null,
        };
    },
    getters: {
        revision: (state: IUIStateLam) => state._revision as ImmMap<keyof IActivityRevision, any>,
        assets: (state: IUIStateLam) => state._assets as IContentBlock[],
        selectedBlock: (state: IUIStateLam) => state._selectedBlock,
        selectedGrid: (state: IUIStateLam) => state._selectedGrid,
        defaultCommPreference: (state: IUIStateLam) => state._defaultCommPreference,
        selectedPartitions: (state: IUIStateLam) => state._selectedPartitions,
        selectedAssetId: (state: IUIStateLam) => state._selectedAssetId,
        hoveredItem: (state: IUIStateLam) => state._hoveredItem,
        selectedNav: (state: IUIStateLam) => state._selectedNav,
    },
    actions: {
        fetchActivityRevision({ organization, activityId, revisionId, partitionIds }) {
            if (fetchActivityRevisionDisposable) {
                fetchActivityRevisionDisposable.dispose();
            }
            let cmd = new QueryCommand(organization.get('organization') as number);
            let query = new Query('activityRevision');
            query = query
                .id(revisionId)
                .activityId(activityId)
                .includeContent(revisionId + '');
            if (partitionIds && partitionIds.length > 0) {
                query = query.partitionIds(partitionIds);
            }
            cmd = cmd.query(query).sortKey('id').sortOrder('desc').limit(1);
            const conn = AppServer.connection(organization);

            return new Promise((resolve, reject) => {
                fetchActivityRevisionDisposable = conn
                    .execute(cmd)
                    .filter(
                        (response) =>
                            response.result.matchesQuery ||
                            (response.result.data.length > 0 &&
                                parseInt(response.result.data[0]?.activityId, 10) === parseInt(activityId, 10))
                    )
                    .subscribe((response) => {
                        this._revision = ImmMap(response.result.data[0]);
                        const dcp = this._revision.getIn([
                            'content',
                            'channels',
                            'features',
                            'lsmoptin',
                            'defaultCommunicationPreference',
                        ]) as COMM_PREFERENCE;
                        if (dcp && ['optin', 'optout'].includes(dcp)) {
                            this._defaultCommPreference = dcp;
                        }
                        resolve(this.revision);
                    }, reject);
            });
        },
        fetchAssets({ organization, tags, partitionIds }) {
            if (fetchAssetsDisposable) {
                fetchAssetsDisposable.dispose();
            }
            let cmd = new QueryCommand(organization.get('organization') as number);
            let query = new Query('contentBlock');
            if (partitionIds && partitionIds.length > 0) {
                query = query.partitionIds(partitionIds);
            }
            query = query.isPublished(true).tag(tags);
            cmd = cmd.query(query).sortKey('id').sortOrder('desc').limit(FETCH_ASSET_LIMIT);

            const conn = AppServer.connection(organization);
            return new Promise((resolve, reject) => {
                fetchAssetsDisposable = conn
                    .execute(cmd)
                    .first()
                    .subscribe((response) => {
                        this._assets = response.result.data;
                        resolve(this._assets);
                    }, reject);
            });
        },
        updatePartitionOptin({ organization, params }) {
            if (!params.id) {
                throw new Error('Activity ID is required to update partition optin');
            }
            if (updatePartitionOptinDisposable) {
                updatePartitionOptinDisposable.dispose();
            }

            const cmd = new WriteCommand<Record<string, any>>('activity', organization.get('organization'));
            return new Promise((resolve, reject) => {
                updatePartitionOptinDisposable = AppServer.connection(organization)
                    .execute(cmd.data(params))
                    .first()
                    .subscribe((response) => {
                        resolve(response);
                    }, reject);
            });
        },
        updatePartitionContent({ organization, params }) {
            if (!params.id) {
                throw new Error('Activity ID is required to update partition optin');
            }

            if (updatePartitionContentDisposable) {
                updatePartitionContentDisposable.dispose();
            }

            const cmd = new WriteCommand<Record<string, any>>('activity', organization.get('organization'));
            return new Promise((resolve, reject) => {
                updatePartitionContentDisposable = AppServer.connection(organization)
                    .execute(cmd.data(params))
                    .first()
                    .subscribe((response) => {
                        resolve(response);
                    }, reject);
            });
        },
        updatePartitionSelectionGroup({ organization, params }) {
            if (!params.id) {
                throw new Error('Activity ID is required to update partition groups');
            }

            if (updatePartitionLamGroupsDisposable) {
                updatePartitionLamGroupsDisposable.dispose();
            }

            const cmd = new WriteCommand<Record<string, any>>('activity', organization.get('organization'));
            return new Promise((resolve, reject) => {
                updatePartitionLamGroupsDisposable = AppServer.connection(organization)
                    .execute(cmd.data(params))
                    .first()
                    .subscribe((response) => {
                        console.log('lam.ts: Update partition grouping response', response);
                        resolve(response);
                    }, reject);
            });
        },

        updateSelectedBlock(selectedBlock: any) {
            this._selectedBlock = selectedBlock;
        },
        updateSelectedGrid(selectedGrid: ISelectedGrid | null) {
            this._selectedGrid = selectedGrid;
        },
        updateSelectedPartitions(partitions: number[]) {
            this._selectedPartitions = partitions;
        },
        updateSelectedAssetId(assetId: string) {
            this._selectedAssetId = assetId;
        },
        updateSelectedNav(selectedNav: any) {
            this._selectedNav = selectedNav;
        },
        updateHoveredItem(hoveredItem: ISelectedBlock | ISelectedGrid | null) {
            this._hoveredItem = hoveredItem;
        },

        reset() {
            if (fetchActivityRevisionDisposable) {
                fetchActivityRevisionDisposable.dispose();
            }
            this._revision = ImmMap();
            this._selectedBlock = null;
            this._selectedGrid = null;
            this._selectedNav = null;
            this._hoveredItem = null;

            this.$reset();
        },
    },
});
