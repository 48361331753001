import Rx from 'rx';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { IActivity } from '../../types/IActivity';
import AppServer from '../../api/AppServer';
import Query from '../../api/Query';
import QueryCommand, { IQueryCommandResponse } from '../../api/QueryCommand';
import WriteCommand from '../../api/WriteCommand';
import type { IActivityRevision } from '../../types/IActivityRevision';

import { useStoreLAM } from '../../lsm/state/lam';
import { useStoreRoot } from './root';

interface IUIStateActivity {
    _activity: any;
    _latestRevision: IActivityRevision;
}

interface IPartitionContent {
    lsm?: Record<string, string>[];
    optin?: boolean;
    assetOptins?: Record<string, boolean>; // contentId: true | false
}

let updateActivityDisposable!: Rx.Disposable;
let fetchActivityRevisionDisposable!: Rx.Disposable;
let saveCouponPairingDisposable!: Rx.Disposable;

export const useStoreActivity = defineStore('activity', {
    state: () => {
        return {
            _activity: {} as any,
            _latestRevision: {} as IActivityRevision,
        };
    },
    getters: {
        activity: (state: IUIStateActivity) => state._activity as any,
        latestRevision: (state: IUIStateActivity) => state._latestRevision as IActivityRevision,
    },
    actions: {
        fetchActivity({ organization, activityId, partitionIds }) {
            let cmd = new QueryCommand(organization.get('organization') as number);
            let query = new Query('activity');
            if (partitionIds && partitionIds.length > 0) {
                query = query.partitionIds(partitionIds);
            }
            cmd = cmd.query(query.id(activityId)).sortKey('id').limit(1);
            const conn = AppServer.connection(organization);
            return conn
                .execute(cmd)
                .filter((response: IQueryCommandResponse<IActivity>) => {
                    // Return true if the query matches the response, or if the response contains the activity ID
                    return (
                        response.result.matchesQuery ||
                        (response.result.data.length > 0 &&
                            response.result.data.filter((activity) => activity.id === parseInt(activityId, 10)).length >
                                0)
                    );
                })
                .flatMapLatest((response: IQueryCommandResponse<IActivity>) => {
                    this._activity = response.result.data.filter(
                        (activity) => activity.id === parseInt(activityId, 10)
                    )[0];
                    if (this._activity.date) {
                        this._activity.__dayjsDt = dayjs(this._activity.date);
                    }
                    return Rx.Observable.of(this._activity);
                });
        },

        updateActivity({ organization, params }) {
            const command = new WriteCommand<Record<string, any>>('activity', organization.get('organization'));
            if (updateActivityDisposable) {
                updateActivityDisposable.dispose();
            }

            return new Promise((resolve, reject) => {
                updateActivityDisposable = AppServer.connection(organization)
                    .execute(command.data(params))
                    .first()
                    .subscribe((response) => {
                        resolve(response);
                    }, reject);
            });
        },

        fetchLatestActivityRevision({ organization }) {
            if (fetchActivityRevisionDisposable) {
                fetchActivityRevisionDisposable.dispose();
            }

            let cmd = new QueryCommand(organization.get('organization') as number);
            let query = new Query('activityRevision');
            query = query
                .id(this._activity.latestRevisionId)
                .activityId(this._activity.id)
                .includeContent(this._activity.latestRevisionId + '');

            if (this._activity.partitionIds && this._activity.partitionIds.length > 0) {
                query = query.partitionIds(this._activity.partitionIds);
            }

            cmd = cmd.query(query).sortKey('id').sortOrder('desc').limit(1);
            const conn = AppServer.connection(organization);

            return new Promise((resolve, reject) => {
                fetchActivityRevisionDisposable = conn
                    .execute(cmd)
                    .filter(
                        (response) =>
                            response.result.matchesQuery ||
                            (response.result.data.length > 0 &&
                                parseInt(response.result.data[0]?.activityId, 10) === parseInt(this._activity.id, 10))
                    )
                    .first()
                    .subscribe((response) => {
                        this._latestRevision = response.result.data[0];
                        resolve(this._latestRevision);
                    }, reject);
            });
        },

        removePartitionContent({ contentId }) {
            if (!contentId) {
                throw new Error('Block content ID is required');
            }

            if (!this._activity.partitionContent) {
                return;
            }

            const storeLam = useStoreLAM();
            const storeRoot = useStoreRoot();
            Object.keys(this._activity.partitionContent).forEach((pid) => {
                const pcontent: IPartitionContent = this._activity.partitionContent[pid];
                if (pcontent && pcontent.assetOptins && Object.hasOwn(pcontent.assetOptins, contentId)) {
                    // Delete asset optins from partition content
                    delete pcontent.assetOptins[contentId];
                }

                if (pcontent && pcontent.lsm && Array.isArray(pcontent.lsm)) {
                    // Remove block from partition content
                    pcontent.lsm = pcontent.lsm.filter((block) => block.contentId !== contentId);
                }
            });

            storeLam.updatePartitionContent({
                organization: storeRoot.organization,
                params: {
                    id: this._activity.id,
                    partitionContent: this._activity.partitionContent,
                    partitionAssetOptin: this._activity.partitionContent,
                },
            });
        },

        saveCouponPairing({ organization, payload }) {
            if (!payload.couponTypeName && !payload.couponTypeId) {
                throw new Error('Missing coupon type for coupon pairing');
            }
            if (!payload.activityId) {
                throw new Error('Missing activity id for coupon pairing');
            }
            const command = new WriteCommand<Record<string, any>>(
                'activityCouponType',
                organization.get('organization')
            );
            if (saveCouponPairingDisposable) {
                saveCouponPairingDisposable.dispose();
            }

            return new Promise((resolve, reject) => {
                saveCouponPairingDisposable = AppServer.connection(organization)
                    .execute(command.data(payload))
                    .first()
                    .subscribe((response) => {
                        resolve(response);
                    }, reject);
            });
        },

        reset() {
            if (updateActivityDisposable) {
                updateActivityDisposable.dispose();
            }
            if (saveCouponPairingDisposable) {
                saveCouponPairingDisposable.dispose();
            }
            this._activity = {};
            this._latestRevision = {} as IActivityRevision;
            this.$reset();
        },
    },
});
